import { useEffect, useState } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Loading, useAppBridge, useNavigate } from '@shopify/app-bridge-react'
import SupportLink from '../../lib/SupportLink'

const SupportPage = () => {
    const app = useAppBridge()
    const redirect = Redirect.create(app)
    const [hasRedirected, setHasRedirected] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
      if (!hasRedirected) {
        redirect.dispatch(Redirect.Action.REMOTE, {
          url: SupportLink().getSupport(),
          newContext: true,
        })
        setHasRedirected(true)
      }
    }, [redirect, hasRedirected])

    useEffect(() => {
      navigate('/')
    }, [navigate])

    return <Loading />
}

export default SupportPage
