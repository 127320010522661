/* eslint-disable no-useless-escape */
import { Button, TextField } from '@shopify/polaris'
import { useEffect } from 'react'
import { relativeURL } from '../../../../urlHelper'

function GoToURL({ urlPath, setUrlPath, selectedMode, currentURL, setCurrentURL, fullUrl }) {

  useEffect(() => {
    setCurrentURL(fullUrl())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPath])

  const validate = () => {
    const urlPattern = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\/[\w\-\.]+)*\/?$/

    return urlPattern.test(currentURL)
  }

  const error = () => validate() ? '' : 'Invalid'

  const go = () => {
    if (!validate()) return
    // replace domain
    setUrlPath(relativeURL(currentURL))
    setCurrentURL(fullUrl(relativeURL(currentURL)))
  }

  if (selectedMode !== 'interact') return <></>

  return (
    <div className="GoToURL">
      <TextField value={currentURL} error={error()} onChange={setCurrentURL}></TextField>
      <Button primary onClick={go}>Go</Button>
    </div>
  )
}

export default GoToURL
