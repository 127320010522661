import { Modal } from '@shopify/polaris'
import Modes from '../../../../../lib/content-test/shared/Modes'

const ChangeModeModal = ({ openModal, setOpenModal, setUrlPath, currentURL, setCurrentURL, fullUrl, path, setPath, selectedMode, setSelectedMode, dispatch }) => {

  const relativeURL = (domain) => {
    const hasProtocol = () => /^(https?:\/\/)/.test(domain)
    const protocol = hasProtocol() ? '' : 'https://'

    return new URL(`${protocol}${domain}`).pathname
  }

  const handleClose = () => {
    setSelectedMode(Modes.Edit)
    setOpenModal(false)
  }

  const handleChange = () => {

    if (path) {
      setUrlPath(path)
      setPath('')
    } else {
      setUrlPath(relativeURL(currentURL))
      setCurrentURL(fullUrl(relativeURL(currentURL)))
    }

    dispatch({ type: 'DELETE_CHANGES' })
    setOpenModal(false)
    setSelectedMode(Modes.Edit)
  }

  if (selectedMode !== Modes.Interact) return <></>

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      title="Progress will be lost"
      primaryAction={{
        destructive: true,
        content: 'Yes',
        onAction: handleChange,
      }}
      secondaryActions={[
        {
          content: 'No',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        Moving to a different page will cause all changes made on the current page to be lost. Would you like to proceed?
      </Modal.Section>
    </Modal>
  )
}

export default ChangeModeModal
