/* eslint-disable react-hooks/exhaustive-deps */
import { FullscreenBar } from '@shopify/polaris'
import ChangeList from './components/ChangeList/ChangeList'
import { useCallback } from 'react'
import Save from './components/Save/Save'
import Mode from './components/Mode/Mode'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Fullscreen } from '@shopify/app-bridge/actions'
import useCustomHistory from '../../../../hooks/useCustomHistory'
import GoToURL from './components/GoToURL/GoToURL'
import DeviceType from './components/DeviceType/DeviceType'
import './style.scss'

function NavBar(props) {

  const { contentTest, onSave, selectedMode, setSelectedMode, urlPath, setUrlPath, setOpenModal, deviceType, setDeviceType, setSelectedElement, sendElementChangeToThePage, currentURL, setCurrentURL, fullUrl } = props

  const app = useAppBridge()
  const fullscreen = Fullscreen.create(app)
  fullscreen.dispatch(Fullscreen.Action.ENTER)
  const history = useCustomHistory()

  const handleActionClick = useCallback(() => {
    fullscreen.dispatch(Fullscreen.Action.EXIT)
    return history.push('/')
  }, [])

  if (!contentTest) return <></>

  return (
    <FullscreenBar onAction={handleActionClick}>
      <div className="NavBar">
        <ChangeList contentTest={contentTest} setSelectedElement={setSelectedElement} sendElementChangeToThePage={sendElementChangeToThePage}/>
        <Mode selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
        <div className="SpecificMode Item">
          <div className="Edit">
            <DeviceType selectedMode={selectedMode} deviceType={deviceType} setDeviceType={setDeviceType}/>
            <Save contentTest={contentTest} selectedMode={selectedMode} onSave={onSave}/>
          </div>
          <div className="Interact">
            <GoToURL urlPath={urlPath} setUrlPath={setUrlPath} selectedMode={selectedMode} setOpenModal={setOpenModal} currentURL={currentURL} setCurrentURL={setCurrentURL} fullUrl={fullUrl} />
          </div>
        </div>
      </div>
    </FullscreenBar>
  )
}

export default NavBar
